import { Divider, Grid, Icon, Skeleton, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { CompanyInformation } from "components/CompanyInformation/CompanyInformation";
import { Link, useParams } from "react-router-dom";
import { useGetCompany } from "hooks/companies/useGetCompany";
import MDAlert from "components/MDAlert";
import getLocalString from "constants/Localization";
import { getPrimaryContact } from "utils/companies/getPrimaryContact";
import { getRoute } from "utils/routing";
import { CompanyPrimaryContact } from "components/CompanyPrimaryContact/CompanyPrimaryContact";
import { getSecondaryContacts } from "utils/companies/getSecondaryContacts";
import { CompanyOtherContacts } from "components/CompanyOtherContacts/CompanyOtherContacts";
import { isNotNilOrEmpty } from "ramda-adjunct";
import { CompanyProposals } from "modules/CompanyProposals/CompanyProposals";
import { CompanyOpportunities } from "modules/CompanyOpportunities/CompanyOpportunities";
import CompanyHeader from "partials/Header/CompanyHeader";
import { CompanyJobs } from "modules/companies/CompanyJobs/CompanyJobs";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";
import { UserPermissions } from "generated/graphql";

function CompanyPageLoader() {
  return <Skeleton />;
}

export default function ViewCompanyPage() {
  const { companyId } = useParams();
  const { loading, data: company, error } = useGetCompany({ id: companyId });
  const { hasPermissions } = useCheckPermissions();

  if (loading) {
    return <CompanyPageLoader />;
  }

  const editIcon = hasPermissions(UserPermissions.MANAGE_COMPANIES) && (
    <MDTypography
      component={Link}
      to={getRoute("companies.update", [["companyId", company.id]])}
      variant="body2"
      color="secondary"
    >
      <Tooltip title="Edit" placement="top">
        <Icon>edit</Icon>
      </Tooltip>
    </MDTypography>
  );

  const secondaryContacts = getSecondaryContacts(company);
  const hasSecondaryContacts = isNotNilOrEmpty(secondaryContacts);

  return (
    <DashboardPage>
      <MDBox mb={2} />
      <CompanyHeader company={company}>
        {error ? (
          <MDBox p={2}>
            <MDAlert color="error">
              {getLocalString("generic.failure")}. Details. {error.message}
            </MDAlert>
          </MDBox>
        ) : (
          <>
            <MDBox mt={5} mb={3}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={hasSecondaryContacts ? 4 : 6}>
                  <CompanyInformation company={company} icon={editIcon} />
                </Grid>
                <Grid item xs={12} md={hasSecondaryContacts ? 4 : 6} sx={{ display: "flex" }}>
                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                  <CompanyPrimaryContact contact={getPrimaryContact(company)} icon={editIcon} />
                  <Divider orientation="vertical" sx={{ mx: 0 }} />
                </Grid>
                {secondaryContacts?.length > 0 ? (
                  <Grid item xs={12} md={4}>
                    <CompanyOtherContacts contacts={secondaryContacts} icon={editIcon} />
                  </Grid>
                ) : null}
              </Grid>
            </MDBox>
            <CompanyOpportunities company={company} />
            <CompanyProposals company={company} />
            <CompanyJobs company={company} />
          </>
        )}
      </CompanyHeader>
    </DashboardPage>
  );
}
