import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import { Link } from "react-router-dom";
import { getRoute } from "utils/routing";
import NotificationList from "./NotificationList";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";
import { UserPermissions } from "generated/graphql";

export default function NotificationsIndex() {
  const { hasPermissions } = useCheckPermissions();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Card>
          <MDBox
            p={3}
            lineHeight={1}
            display="flex"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <MDTypography variant="h5" fontWeight="medium">
              Email Triggers
            </MDTypography>
            {hasPermissions(UserPermissions.MANAGE_NOTIFICATION_TRIGGERS) && (
              <MDTypography
                as={Link}
                to={getRoute("organization-settings.email-triggers.create-email-triggers")}
              >
                <MDButton color="blue">
                  <Icon>add</Icon>&nbsp; Create Notification Trigger
                </MDButton>
              </MDTypography>
            )}
          </MDBox>
          <NotificationList />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
