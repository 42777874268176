import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { UserPermissions } from "generated/graphql";
import useGetRolesPermissions from "hooks/organization/permissions/useGetRolesPermissions";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardPage from "layouts/DashboardPage/DashboardPage";
import UserPermissionsTable from "modules/UserPermissions/UserPermissionsTable/UserPermissionsTable";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";

export default function UserPermissionsPage() {
  const { data, loading } = useGetRolesPermissions();
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        sx={{
          p: 2,
          mb: 6,
        }}
      >
        <MDTypography variant="h4">User Permissions</MDTypography>
        {!loading && data && <UserPermissionsTable data={data} />}
      </Card>
    </DashboardLayout>
  );
}
