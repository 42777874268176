import { CreateOpportunityInput, Opportunity } from "generated/graphql";
import * as z from "zod";

export const defaultValues = {
  name: "",
  source: null,
  companyId: "",
  contactId: "",
  addressLine1: "",
  addressLine2: "",
  addressCity: "",
  addressState: "",
  addressZip: "",
  addressCountry: "US",
  dueAt: null,
  userId: "",
  notes: "",
  products: { connect: [] },
  projectFilesFolderId: null,
};

export const getDefaultValues = (initialValues: Opportunity): CreateOpportunityInput => {
  // We need to put the company contacts under upsert
  return defaultValues;
};

export const schema = z.object({
  companyId: z
    .string({
      required_error: "Client is Required",
    })
    .min(1, "Client is Required"),
  contactId: z
    .string({
      required_error: "Contact is Required",
    })
    .min(1, "Contact is Required"),
  userId: z
    .string({
      required_error: "Sales Person is Required",
      invalid_type_error: "Sales Person is Required",
    })
    .min(1, "Sales Person is Required"),
  source: z.null().or(z.string().optional()),
  projectFilesFolderId: z.null().or(z.string().optional()),
  name: z.string().min(1, "Name is Required"),
  dueAt: z.custom(
    (value: string) => {
      if (!value) {
        return true;
      }

      try {
        const date = new Date(value);
        // @ts-ignore
        if (date instanceof Date && !isNaN(date)) {
          return true;
        }
      } catch (e) {}

      return false;
    },
    {
      message: "Due At is Required",
    }
  ),
  addressLine1: z.string().min(1, "Address Line 1 is Required"),
  addressCity: z.string().min(1, "Address City is Required"),
  addressState: z.string().min(1, "Address State is Required"),
  addressZip: z.string().min(1, "Address Zip is Required"),
  addressCountry: z
    .string({ invalid_type_error: "Address Country is Required" })
    .min(1, "Address Country is Required"),
  notes: z.string().nullable(),
  products: z
    .object({
      connect: z.array(z.string().min(1)),
    })
    .or(z.array(z.string().optional()).optional()),
});
