import { useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import FormField from "components/FormField/FormField";
import { Avatar } from "@mui/material";
import MDButton from "components/MDButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { InviteUserInput, UserRole } from "generated/graphql";
import usePreviewSubscriptionChange from "hooks/subscriptions/usePreviewSubscriptionChange";
import CountrySelect from "components/CountrySelect/CountrySelect";
import StateSelect from "components/StateSelect/StateSelect";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import getLocalString from "constants/Localization";
import { useBitumioContext } from "providers/BitumioContext";
import { inviteUserRolesMapping } from "helpers/roles";

interface InviteUserProps {
  onSubmit: SubmitHandler<InviteUserInput>;
  loading: boolean;
}

const options = Object.keys(UserRole).map((key) => ({
  label: key,
  value: UserRole[key],
}));

const PreviewAvatar = ({ value }: { value: FileList }) => {
  const previewImage = useMemo(() => {
    return URL.createObjectURL(value[0]);
  }, [value]);

  return <Avatar src={previewImage} sx={{ width: 56, height: 56 }} />;
};

function InviteUser({ onSubmit }: InviteUserProps): JSX.Element {
  const {
    handleSubmit,
    register,
    watch,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<InviteUserInput>({
    defaultValues: {
      role: null,
    },
  });
  const [{ signedInUser }] = useBitumioContext();
  const { show } = useGlobalMessage();
  const watchAvatar = watch("avatar");
  const watchRole = watch("role");
  const [
    previewSubscriptionChange,
    { error, loading: previewSubscriptionChangeLoading, totals, success },
  ] = usePreviewSubscriptionChange();

  // As soon as we load the form we need to know if there will be a charge
  useEffect(() => {
    previewSubscriptionChange({ quantityChange: 1 });
  }, [previewSubscriptionChange]);

  const buttonDisabled = previewSubscriptionChangeLoading || !success;

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async ({ avatar, ...rest }: InviteUserInput) => {
          // @ts-expect-error: FIX update types
          const { success } = await onSubmit({ ...rest, avatar: avatar?.[0] ? avatar[0] : null });
          if (success) {
            reset();
            show({ message: getLocalString("users.invite-success") });
          }
        })}
      >
        <MDTypography variant="h5" mb={2}>
          User Information
        </MDTypography>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="First Name"
                placeholder="First Name"
                error={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Last Name"
                placeholder="Last Name"
                error={errors.lastName}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField
                type="text"
                label="Email"
                placeholder="Email"
                {...register("email", { required: "Email required" })}
                error={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <Controller
                control={control}
                name="role"
                render={({ field }) => (
                  // @ts-expect-error: FIX update types
                  <Autocomplete
                    {...field}
                    options={
                      options.filter((x) =>
                        inviteUserRolesMapping[signedInUser.roles[0]].some((y) => y === x.value)
                      ) as {
                        label: any;
                        value: any;
                      }[]
                    }
                    onChange={(e, value: (typeof options)[number]) =>
                      field.onChange(value?.value || null)
                    }
                    getOptionLabel={(option) =>
                      typeof option === "string"
                        ? options.find((o) => o.value === option)?.label
                        : option.label
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.label === value.label || option.value === value
                    }
                    renderInput={(params) => (
                      <MDInput {...params} label="Role" variant="standard" />
                    )}
                  />
                )}
                rules={{ required: "Role required" }}
              />
              <MDTypography color="error" variant="caption">
                {errors.role?.message}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField type="text" label="Address Line 1" {...register("addressLine1")} />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField type="text" label="Address Line 2" {...register("addressLine2")} />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField type="text" label="Address City" {...register("addressCity")} />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <StateSelect
                name="addressState"
                label="Address State"
                control={control}
                countryFieldName="addressCountry"
                setValue={setValue}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} mb={2}>
              <FormField type="text" label="Address Zip" {...register("addressZip")} />
            </Grid>
            <Grid item xs={12} sm={6} mb={2}>
              <CountrySelect
                name="addressCountry"
                label="Address Country"
                defaultValue="US"
                control={control}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={6} mb={2}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={2} mb={2}>
                  {watchAvatar?.length > 0 ? (
                    <PreviewAvatar value={watchAvatar} />
                  ) : (
                    <Avatar src={null} />
                  )}
                </Grid>
                <Grid item xs={10} mb={2}>
                  <MDButton component="label" variant="outlined" color="info">
                    Upload Avatar
                    <input
                      hidden
                      accept="image/png, image/jpeg"
                      type="file"
                      {...register("avatar")}
                    />
                  </MDButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} mb={2}>
              {totals &&
                watchRole &&
                ![UserRole.EMPLOYEE, UserRole.SUBCONTRACTOR].some((x) => x === watchRole) && (
                  <MDTypography variant="body2" color="info" mb={2}>
                    Today you will be charged ${(totals.totalToday / 100).toFixed(2)}. Your next
                    renewal will be ${(totals.totalOnRenewal / 100).toFixed(2)}
                  </MDTypography>
                )}
              <MDButton type="submit" variant="gradient" color="success" disabled={buttonDisabled}>
                Invite
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default InviteUser;
