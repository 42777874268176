import { zodResolver } from "@hookform/resolvers/zod";
import {
  UpdateRolesWithPermissionsInputSchema,
  schema,
  getDefaultValues,
  cleanRolesWithPermissionsUpdateInput,
} from "DDD/action-objects/RolesWithPermissionsUpdate";
import MDBox from "components/MDBox";
import { RolesWithPermissions } from "generated/graphql";
import { FormProvider, useForm } from "react-hook-form";
import PermissionFields from "../PermissionFields/PermissionFields";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useCallback } from "react";
import useUpdateRolesPermissions from "hooks/organization/permissions/useUpdateRolesPermissions";
import useGlobalMessage from "hooks/notifications/useGlobalMessage";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";

const UserPermissionsTable: React.FC<{ data: RolesWithPermissions }> = ({ data }) => {
  const methods = useForm<UpdateRolesWithPermissionsInputSchema>({
    defaultValues: getDefaultValues({
      rolesWithPermissions: data,
    }) as UpdateRolesWithPermissionsInputSchema,
    resolver: zodResolver(schema),
  });
  const [updateRolesWithPermissions] = useUpdateRolesPermissions();
  const onSubmit = useCallback(async (values: UpdateRolesWithPermissionsInputSchema) => {
    const cleanedInput = cleanRolesWithPermissionsUpdateInput(values);
    await updateRolesWithPermissions({
      variables: {
        input: cleanedInput,
      },
    });
  }, []);
  return (
    <FormProvider {...methods}>
      <MDBox component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <MDBox
          display="flex"
          gap={2}
          alignItems="center"
          justifyContent="center"
          py={2}
          flexDirection="column"
        >
          <Grid container spacing={0.5}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4.4}></Grid>
            <Grid item xs={2.2} width="100%">
              <MDBox bgColor="#f5f5f5" p={2} border="1px solid #FFF">
                <MDTypography
                  variant="h6"
                  noWrap
                  sx={{
                    textAlign: "center",
                  }}
                >
                  Mobile Only
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>

          <Grid container spacing={0.5}>
            <Grid item xs={4}>
              <MDBox p={4}></MDBox>
              <MDBox display="flex" flexDirection="column" ml={4} gap={1.5}>
                <MDTypography variant="body2" fontWeight="bold">
                  View Dashboard
                </MDTypography>
                <MDTypography variant="body2" fontWeight="bold">
                  View Maps
                </MDTypography>
                <MDTypography variant="body2" fontWeight="bold">
                  Companies / Contacts
                </MDTypography>
                <MDBox ml={2} display="flex" flexDirection="column" gap={1.5}>
                  <MDTypography variant="body2">View</MDTypography>
                  <MDTypography variant="body2">Create/Update</MDTypography>
                </MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  Opportunities
                </MDTypography>
                <MDBox ml={2} display="flex" flexDirection="column" gap={1.5}>
                  <MDTypography variant="body2">View</MDTypography>
                  <MDTypography variant="body2">Create/Update</MDTypography>
                </MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  Proposals
                </MDTypography>
                <MDBox ml={2} display="flex" flexDirection="column" gap={1.5}>
                  <MDTypography variant="body2">View All Proposals</MDTypography>
                  <MDTypography variant="body2">View Only Assigned</MDTypography>
                  <MDTypography variant="body2">Create/Update</MDTypography>
                </MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  Jobs
                </MDTypography>
                <MDBox ml={2} display="flex" flexDirection="column" gap={1.5}>
                  <MDTypography variant="body2">View All Jobs</MDTypography>
                  <MDTypography variant="body2">View Crew Jobs</MDTypography>
                  <MDTypography variant="body2">View Assigned Jobs</MDTypography>
                  <MDTypography variant="body2">View Job Info</MDTypography>
                  <MDTypography variant="body2">Push To Quickbooks</MDTypography>
                  <MDTypography variant="body2">View Job Financial Summaries</MDTypography>
                </MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  Job Phases
                </MDTypography>
                <MDBox ml={2} display="flex" flexDirection="column" gap={1.5}>
                  <MDTypography variant="body2">View Work Order</MDTypography>
                  <MDTypography variant="body2">Input Job Info / Costs</MDTypography>
                </MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  Schedule
                </MDTypography>
                <MDBox ml={2} display="flex" flexDirection="column" gap={1.5}>
                  <MDTypography variant="body2">View</MDTypography>
                  <MDTypography variant="body2">Manage Schedule</MDTypography>
                </MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  Product Settings
                </MDTypography>
                <MDTypography variant="body2" fontWeight="bold">
                  Organization Settings
                </MDTypography>
                <MDBox ml={2} display="flex" flexDirection="column" gap={1.5}>
                  <MDTypography variant="body2">Users</MDTypography>
                  <MDTypography variant="body2">Data Imports</MDTypography>
                  <MDTypography variant="body2">Notification Triggers</MDTypography>
                  <MDTypography variant="body2">Proposal Settings</MDTypography>
                  <MDTypography variant="body2">Organization Info</MDTypography>
                  <MDTypography variant="body2">Manage Subscription</MDTypography>
                  <MDTypography variant="body2">Email Settings</MDTypography>
                  <MDTypography variant="body2">Employees</MDTypography>
                  <MDTypography variant="body2">Generate Reports</MDTypography>
                  <MDTypography variant="body2">Linked Services</MDTypography>
                </MDBox>
                <MDTypography variant="body2" fontWeight="bold">
                  Knowledge Base
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={1}>
              <MDBox bgColor="#f5f5f5" p={2} border="1px solid #FFF">
                <MDTypography variant="h6" noWrap>
                  Admin
                </MDTypography>
              </MDBox>
              <PermissionFields arrayFieldName="admin" />
            </Grid>
            <Grid item xs={1.2}>
              <MDBox bgColor="#f5f5f5" py={2} px={2} border="1px solid #FFF">
                <MDTypography variant="h6" noWrap>
                  Office Manager
                </MDTypography>
              </MDBox>
              <PermissionFields arrayFieldName="officeManager" />
            </Grid>
            <Grid item xs={1.2}>
              <MDBox bgColor="#f5f5f5" py={2} px={2} border="1px solid #FFF">
                <MDTypography variant="h6" noWrap>
                  Sales Person
                </MDTypography>
              </MDBox>
              <PermissionFields arrayFieldName="salesperson" />
            </Grid>
            <Grid item xs={1}>
              <MDBox bgColor="#f5f5f5" py={2} px={2} border="1px solid #FFF">
                <MDTypography variant="h6" noWrap>
                  Foreman
                </MDTypography>
              </MDBox>
              <PermissionFields arrayFieldName="foreman" />
            </Grid>
            <Grid item xs={1}>
              <MDBox bgColor="#f5f5f5" py={2} px={2} border="1px solid #FFF">
                <MDTypography variant="h6" noWrap>
                  Employee
                </MDTypography>
              </MDBox>
              <PermissionFields arrayFieldName="employee" />
            </Grid>
            <Grid item xs={1.2}>
              <MDBox bgColor="#f5f5f5" py={2} px={2} border="1px solid #FFF">
                <MDTypography variant="h6" noWrap>
                  Subcontractor
                </MDTypography>
              </MDBox>
              <PermissionFields arrayFieldName="subcontractor" />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox display="flex" justifyContent="flex-end">
          <MDButton color="success" type="submit">
            Save
          </MDButton>
        </MDBox>
      </MDBox>
    </FormProvider>
  );
};

export default UserPermissionsTable;
