import {
  Box,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import MDAlert from "components/MDAlert";
import { GetJobsSortKey, Job, JobPhase, SortDirection, UserPermissions } from "generated/graphql";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import { useNavigate } from "react-router";
import parseGraphQLError from "utils/graphQL/parseGraphQLError";
import { getRoute } from "utils/routing";
import { capitalCase } from "change-case";
import formatCentsToUSD from "utils/money/formatCentsToUSD";
import React, { useCallback, useState } from "react";
import MDBox from "components/MDBox";
import ScheduleJobPhase from "../ScheduleJobPhase/ScheduleJobPhase";
import dayjs from "dayjs";
import useJobsTableData from "hooks/jobs/useJobsTableData";
import { visuallyHidden } from "@mui/utils";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import useGetJobLazy from "hooks/jobs/useGetJobLazy";
import { useEffect } from "react";
import View from "modules/jobs/View";
import ViewPhases from "modules/JobPhases/View";
import MDTypography from "components/MDTypography";
import Title from "../WorkOrder/Title";
import MDButton from "components/MDButton";
import useGetJobPhaseLazy from "hooks/jobs/job-phases/useGetJobPhaseLazy";
import { CustomJobPhase } from "hooks/jobs/job-phases/useGetJobPhase";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&.outer.even": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.outer.odd": {
    backgroundColor: theme.palette.grey["100"],
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function EditButton({ id }: { id: Job["id"] }) {
  const navigate = useNavigate();

  return (
    <IconButton
      onClick={() => {
        navigate(getRoute("jobs.view", [["jobId", id]]));
      }}
    >
      <Icon fontSize="medium">edit</Icon>
    </IconButton>
  );
}

function CalendarButton({
  id,
  onSchedulesUpdate,
}: {
  id: Job["id"];
  onSchedulesUpdate: () => void;
}) {
  const { open, onOpen, onClose: modalClose } = useModalState();

  const onClose = useCallback(() => {
    onSchedulesUpdate();
    modalClose();
  }, [modalClose]);

  return (
    <>
      <IconButton onClick={() => onOpen()}>
        <Icon fontSize="medium">calendar_month</Icon>
      </IconButton>
      <Modal open={open} onClose={onClose}>
        <ScheduleJobPhase id={id} onClose={onClose} />
      </Modal>
    </>
  );
}

export default function JobsSchedules({
  jobIds,
  onSchedulesUpdate,
}: {
  jobIds?: Job["id"][];
  onSchedulesUpdate: () => void;
}) {
  const {
    data: jobsSchedules,
    loading,
    error,
    filterControl,
    filtering,
    organizationProductTypeIds,
    pagination,
    sorting,
  } = useJobsTableData({ jobIds });
  const { open, onClose, onOpen } = useModalState();
  const {
    open: openJobCosting,
    onClose: onJobCostingClose,
    onOpen: onJobCostingOpen,
  } = useModalState();
  const [jobId, setJobId] = useState<string>("");
  const [getJob, { loading: jobLoading, error: jobError, job }] = useGetJobLazy();
  const [getJobPhase, { jobPhase: jobPhaseData }] = useGetJobPhaseLazy();

  useEffect(() => {
    if (jobId) {
      getJob(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    const filtersPersist = JSON.parse(JSON.stringify(filtering));
    filtersPersist["organizationProductTypeIds"] = organizationProductTypeIds;
    localStorage.setItem("JobsSchedulesFilters", JSON.stringify(filtersPersist));
  }, [filtering, organizationProductTypeIds]);

  // console.log(jobsSchedules[0].jobPhases[0])

  const [openStatus, setOpenStatus] = useState<Record<string, boolean | undefined>>({});

  const handleToggle = useCallback((id: Job["id"]) => {
    setOpenStatus((prevState) => ({
      ...prevState,
      [id]: typeof prevState[id] === "undefined" ? true : !prevState[id],
    }));
  }, []);

  if (error) {
    return <MDAlert color="error">{parseGraphQLError(error)}</MDAlert>;
  }
  const { hasPermissions } = useCheckPermissions();
  return (
    <>
      <MDBox my={3}>{filterControl}</MDBox>
      {loading ? (
        <div>Loading ...</div>
      ) : (
        <MDBox
          sx={{
            "& .chevron": {
              transition: "all 1s",
            },
            "& .expand_more": {
              transform: "rotate(180deg)",
            },
          }}
        >
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ display: "table-header-group" }}>
                <TableRow sx={{ backgroundColor: "grey.300" }}>
                  <TableCell
                    align="left"
                    sortDirection={
                      sorting.sortKey === GetJobsSortKey.ID ? SortDirection[sorting.sortKey] : false
                    }
                  >
                    <TableSortLabel
                      active={sorting.sortKey === GetJobsSortKey.ID}
                      direction={
                        sorting.sortKey === GetJobsSortKey.ID
                          ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                          : "asc"
                      }
                      onClick={() => {
                        const isAsc =
                          sorting.sortKey === GetJobsSortKey.ID &&
                          sorting.sortDirection === SortDirection.ASC;
                        sorting.setSort({
                          sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                          sortKey: GetJobsSortKey.ID,
                        });
                      }}
                    >
                      <MDBox display="flex" alignItems="center">
                        Job ID <Icon>sort</Icon>
                      </MDBox>
                      {sorting.sortKey === GetJobsSortKey.ID ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting.sortDirection === SortDirection.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    sortDirection={
                      sorting.sortKey === GetJobsSortKey.STATUS
                        ? SortDirection[sorting.sortKey]
                        : false
                    }
                  >
                    <TableSortLabel
                      active={sorting.sortKey === GetJobsSortKey.STATUS}
                      direction={
                        sorting.sortKey === GetJobsSortKey.STATUS
                          ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                          : "asc"
                      }
                      onClick={() => {
                        const isAsc =
                          sorting.sortKey === GetJobsSortKey.STATUS &&
                          sorting.sortDirection === SortDirection.ASC;
                        sorting.setSort({
                          sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                          sortKey: GetJobsSortKey.STATUS,
                        });
                      }}
                    >
                      <MDBox display="flex" alignItems="center">
                        Status <Icon>sort</Icon>
                      </MDBox>
                      {sorting.sortKey === GetJobsSortKey.STATUS ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting.sortDirection === SortDirection.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="left"
                    sortDirection={
                      sorting.sortKey === GetJobsSortKey.COMPANY
                        ? SortDirection[sorting.sortKey]
                        : false
                    }
                  >
                    <TableSortLabel
                      active={sorting.sortKey === GetJobsSortKey.COMPANY}
                      direction={
                        sorting.sortKey === GetJobsSortKey.COMPANY
                          ? (sorting.sortDirection.toLowerCase() as "asc" | "desc")
                          : "asc"
                      }
                      onClick={() => {
                        const isAsc =
                          sorting.sortKey === GetJobsSortKey.COMPANY &&
                          sorting.sortDirection === SortDirection.ASC;
                        sorting.setSort({
                          sortDirection: isAsc ? SortDirection.DESC : SortDirection.ASC,
                          sortKey: GetJobsSortKey.COMPANY,
                        });
                      }}
                    >
                      <MDBox display="flex" alignItems="center">
                        Company Name <Icon>sort</Icon>
                      </MDBox>
                      {sorting.sortKey === GetJobsSortKey.COMPANY ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sorting.sortDirection === SortDirection.DESC
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Stage Name</TableCell>
                  <TableCell align="left">Contact</TableCell>
                  <TableCell align="left">Address</TableCell>
                  <TableCell align="left">Proposal Won Date</TableCell>
                  <TableCell align="left">Job Total</TableCell>
                  <TableCell align="left">Edit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {jobsSchedules.map((jobSchedule, i) => {
                  return (
                    <React.Fragment key={jobSchedule.id}>
                      <StyledTableRow
                        className={i % 2 === 0 ? "outer even" : "outer odd"}
                        key={jobSchedule.id}
                      >
                        <TableCell sx={{ position: "relative", minWidth: "100px" }}>
                          <MDBox display="flex">
                            <IconButton sx={{ p: 0 }} onClick={() => handleToggle(jobSchedule.id)}>
                              <Icon
                                className={`chevron ${
                                  openStatus[jobSchedule.id] ? "expand_more" : "expand_less"
                                }`}
                              >
                                expand_less
                              </Icon>
                            </IconButton>
                            <MDBox
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                onOpen();
                                setJobId(jobSchedule.id);
                              }}
                            >
                              {jobSchedule.externalId}{" "}
                            </MDBox>
                          </MDBox>
                        </TableCell>
                        <TableCell>{capitalCase(jobSchedule.status)}</TableCell>
                        <TableCell>{jobSchedule.company?.name}</TableCell>
                        <TableCell>{jobSchedule.proposalStage?.name}</TableCell>
                        <TableCell>
                          {jobSchedule.contact?.firstName || jobSchedule.contact?.lastName}
                          <br />
                          {jobSchedule.contact.phone}
                        </TableCell>
                        <TableCell>{jobSchedule.address?.line1}</TableCell>
                        <TableCell>
                          {jobSchedule.proposal.wonAt
                            ? dayjs.utc(jobSchedule.proposal.wonAt).local().format("LLL")
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {formatCentsToUSD(jobSchedule.proposedOverallTotal)}
                        </TableCell>
                        <TableCell>
                          <EditButton id={jobSchedule.id} />
                        </TableCell>
                      </StyledTableRow>
                      {openStatus[jobSchedule.id] && (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>
                            <MDTypography fontSize={"14px"}>
                              <strong>Phase</strong>
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize={"14px"}>
                              <strong>Status</strong>
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize={"14px"}>
                              <strong>Start Date</strong>
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize={"14px"}>
                              <strong>Job Phase Hours</strong>
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize={"14px"}>
                              <strong>Foreman</strong>
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography fontSize={"14px"}>
                              <strong>Total</strong>
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            {hasPermissions(UserPermissions.MANAGE_SCHEDULE) && (
                              <MDTypography fontSize={"14px"}>
                                <strong>Schedule</strong>
                              </MDTypography>
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                      {openStatus[jobSchedule.id] &&
                        jobSchedule.jobPhases.map((jobPhase, y) => (
                          <>
                            <TableRow
                              className={y % 2 === 0 ? "inner even" : "inner odd"}
                              key={jobPhase.id}
                            >
                              <TableCell align="center">
                                <MDBox
                                  sx={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    getJobPhase(jobPhase.id);
                                    setJobId(jobSchedule.id);
                                    onJobCostingOpen();
                                  }}
                                >
                                  {jobPhase.externalId}
                                </MDBox>
                              </TableCell>
                              <TableCell>{jobPhase.organizationProductTypeName}</TableCell>
                              <TableCell>{capitalCase(jobPhase.status)}</TableCell>
                              <TableCell>
                                {jobPhase.startsAt
                                  ? dayjs.utc(jobPhase.startsAt).local().format("LL")
                                  : ""}
                              </TableCell>
                              <TableCell>{jobPhase.jobDuration}</TableCell>
                              <TableCell>{jobPhase.foremanUser?.name ?? "N/A"}</TableCell>
                              <TableCell>
                                {formatCentsToUSD(jobPhase.proposedOverallTotal)}
                              </TableCell>
                              <TableCell>
                                {hasPermissions(UserPermissions.MANAGE_SCHEDULE) && (
                                  <CalendarButton
                                    id={jobPhase.id}
                                    onSchedulesUpdate={onSchedulesUpdate}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                            <Modal open={openJobCosting} onClose={onJobCostingClose}>
                              <Title
                                text={`Job #${jobSchedule.externalId} > ${jobPhase?.organizationProductTypeName}`}
                              />
                              {loading && <>Loading</>}
                              {error && (
                                <MDAlert color="error">
                                  Could not load job phase material detail. Please refresh this page
                                  or try again later.
                                </MDAlert>
                              )}
                              <MDBox my={3}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12}>
                                    {!loading && jobPhaseData && (
                                      <ViewPhases
                                        //@ts-ignore
                                        jobPhase={jobPhaseData}
                                        addPhaseCostCompleted={onJobCostingClose}
                                      />
                                    )}
                                    {loading && <Skeleton />}
                                  </Grid>
                                </Grid>
                              </MDBox>
                            </Modal>
                          </>
                        ))}
                    </React.Fragment>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9}>
                    <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {pagination.component}
                    </MDBox>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </MDBox>
      )}
      <Modal open={open} onClose={onClose} styles={{ overflow: "auto" }}>
        <MDBox my={3} px="20px" overflow={"auto"}>
          <MDBox mb={6}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} lg={6}>
                <MDTypography variant="h4" fontWeight="medium">
                  Update Job {job && `#${job.externalId}`}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {job && <View job={job as Job} />}
              {!job && <Skeleton />}
            </Grid>
          </Grid>
        </MDBox>
      </Modal>
    </>
  );
}
